import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    tNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    shoppingCart: JSON.parse(localStorage.getItem('shoppingCart')) || [],
    tableNumber: localStorage.getItem('tableNumber') || 0,
    menuList: [{
        name: 'Nice Omlette',
        price: 20,
        category: 'breakfast',
      },
      {
        name: 'Breakfast Menu',
        price: 30,
        category: 'breakfast',
      }],
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_CART (state, payload) {
      var found = false
      for (var i = 0; i < state.shoppingCart.length; i++) {
        if (payload === state.shoppingCart[i].name) {
          state.shoppingCart[i].num += 1
          found = true
          break
        }
      }
      if (!found) {
        state.shoppingCart.push({
          name: payload,
          num: 1,
        })
      }
      localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
    },
    REMOVE_CART (state, payload) {
      var value = state.shoppingCart.find(x => (x.name === payload))
      var index = state.shoppingCart.indexOf(value)
      state.shoppingCart.splice(index, 1)
      localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart))
    },
    SET_TABLE (state, payload) {
      state.tableNumber = payload
      localStorage.setItem('tableNumber', payload)
    },
  },
  actions: {

  },
  getters: {
    get_cart: state => state.shoppingCart,
    get_table: state => state.tableNumber,
    get_menu: state => state.menuList,
  },
})
